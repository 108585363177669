import { useRef, useLayoutEffect } from 'react';
import * as THREE from 'three';

const DEFAULT_COLORS = {
	dt: {
		colorStart: new THREE.Color(0xff3e9d),
		colorEnd: new THREE.Color(0x0e1f40),
	},
	interviewer: {
		colorStart: new THREE.Color(0x00adb5),
		colorEnd: new THREE.Color(0x012b71),
	},
};

const VERTEX_SHADER = `
  varying vec3 vNormal;
  void main() {
    vNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`;

const FRAGMENT_SHADER = `
  varying vec3 vNormal;
  uniform vec3 colorStart;
  uniform vec3 colorEnd;
  void main() {
    vec3 interpolatedColor = mix(colorStart, colorEnd, (vNormal.y + 1.0) / 2.0);
    gl_FragColor = vec4(interpolatedColor, 1.0);
  }`;

const SHADER_MATERIAL_CONFIG = {
	uniforms: {
		colorStart: { value: new THREE.Color(0x00adb5) },
		colorEnd: { value: new THREE.Color(0x012b71) },
	},
	vertexShader: `
  varying vec3 vNormal;
  void main() {
    vNormal = normalize(normalMatrix * normal);
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }`,
	fragmentShader: `
  varying vec3 vNormal;
  uniform vec3 colorStart;
  uniform vec3 colorEnd;
  void main() {
    vec3 interpolatedColor = mix(colorStart, colorEnd, (vNormal.y + 1.0) / 2.0);
    gl_FragColor = vec4(interpolatedColor, 1.0);
  }`,
};

const addStarField = (group) => {
	const starsGeometry = new THREE.Geometry();

	for (let i = 0; i < 10000; i++) {
		const star = new THREE.Vector3();
		star.x = THREE.Math.randFloatSpread(2000);
		star.y = THREE.Math.randFloatSpread(2000);
		star.z = THREE.Math.randFloatSpread(2000);
		starsGeometry.vertices.push(star);
	}

	const starsMaterial = new THREE.PointsMaterial({ color: 0xffffff });
	const starField = new THREE.Points(starsGeometry, starsMaterial);
	group.add(starField);
	return starField;
};

export const useThreeScene = ({
	includeStars = false,
	startStationary = false,
	type = 'dt',
} = {}) => {
	const containerRef = useRef();
	const sceneRef = useRef();
	const groupRef = useRef();
	const cameraRef = useRef();
	const rendererRef = useRef();
	const sphereRef = useRef();

	useLayoutEffect(() => {
		const scene = new THREE.Scene();
		const group = new THREE.Group();
		const camera = new THREE.PerspectiveCamera(45, 1, 0.1, 1000);
		const renderer = new THREE.WebGLRenderer({
			alpha: true,
			antialias: true,
		});

		sceneRef.current = scene;
		groupRef.current = group;
		cameraRef.current = camera;
		rendererRef.current = renderer;

		const { colorStart, colorEnd } = DEFAULT_COLORS[type];

		const shaderMaterialConfig = new THREE.ShaderMaterial({
			uniforms: {
				colorStart: { value: colorStart },
				colorEnd: { value: colorEnd },
			},
			vertexShader: VERTEX_SHADER,
			fragmentShader: FRAGMENT_SHADER,
		});

		const customMaterial = new THREE.ShaderMaterial(shaderMaterialConfig);
		const geometry = new THREE.SphereGeometry(15, 100, 100);
		sphereRef.current = new THREE.Mesh(geometry, customMaterial);
		sphereRef.current.position.set(0, 15, 0);
		group.add(sphereRef.current);

		if (includeStars) {
			addStarField(group);
		}

		scene.add(group);

		camera.position.set(0, 0, 100);
		camera.lookAt(scene.position);
		scene.add(camera);

		if (containerRef.current) {
			containerRef.current.appendChild(renderer.domElement);
		}

		const handleResize = () => {
			if (!containerRef.current) return;
			const width = containerRef.current.clientWidth;
			const height = containerRef.current.clientHeight;
			renderer.setSize(width, height);
			camera.aspect = width / height;
			camera.updateProjectionMatrix();
		};

		const resizeObserver = new ResizeObserver(handleResize);
		if (containerRef.current) {
			resizeObserver.observe(containerRef.current);
		}

		window.addEventListener('resize', handleResize, false);
		handleResize();

		let animationFrameId;
		const render = () => {
			let sphere = sphereRef.current;

			if (!startStationary) {
				sphere.geometry.vertices.forEach((vertex) => {
					const offset = sphere.geometry.parameters.radius;
					vertex.normalize();
					const time = performance.now() * 0.003;
					const spikes = 5;
					vertex.multiplyScalar(
						offset +
							0.3 +
							window.noise.perlin3(
								vertex.x * spikes,
								vertex.y * spikes,
								vertex.z * spikes + time
							)
					);
				});

				sphere.geometry.verticesNeedUpdate = true;
				sphere.geometry.normalsNeedUpdate = true;
				sphere.geometry.computeVertexNormals();
				sphere.geometry.computeFaceNormals();
			}

			group.rotation.y += 0.001;
			renderer.render(scene, camera);
			animationFrameId = requestAnimationFrame(render);
		};
		render();

		return () => {
			cancelAnimationFrame(animationFrameId);
			window.removeEventListener('resize', handleResize);
			resizeObserver.disconnect();
			if (containerRef.current) {
				containerRef.current.removeChild(renderer.domElement);
			}
			renderer.dispose();
		};
	}, [includeStars, startStationary]);

	return {
		containerRef,
		scene: sceneRef.current,
		camera: cameraRef.current,
		renderer: rendererRef.current,
		group: groupRef.current,
		sphere: sphereRef.current,
	};
};
