import { Outlet } from 'react-router-dom';

import { InterviewerProvider } from '../context/InterviewerContext';

import Header from '../components/Header';
import Interviewer from '../pages/Interviewer';

const styles = {
	mainContainer: `relative flex flex-col flex-grow min-h-[calc(100dvh)] pb-[75px] sm:pb-[85px] lg:pb-0 custom-bg`,
	childrenContainer: `relative flex grow overflow-hidden`,
};

export default function AuthLayout() {
	return (
		<div className={styles.mainContainer}>
			<Header />
			<main className={styles.childrenContainer}>
				<InterviewerProvider>
					<Outlet />
					<Interviewer type={'dt'} />
				</InterviewerProvider>
			</main>
		</div>
	);
}
