import { createContext, useContext, useReducer } from 'react';

const InterviewerContext = createContext();

export const ActionTypes = {
	START_RECORDING: 'START_RECORDING',
	STOP_RECORDING: 'STOP_RECORDING',
	AUDIO_PLAYING: 'AUDIO_PLAYING',
	AUDIO_FINISHED: 'AUDIO_FINISHED',
	SET_AUDIO_DATA: 'SET_AUDIO_DATA',
	SET_TRANSCRIPT: 'SET_TRANSCRIPT',
	SET_ERROR: 'SET_ERROR',
	SET_CONTAINER_CLIENT: 'SET_CONTAINER_CLIENT',
	RESTART_INTERVIEW: 'RESTART_INTERVIEW',
};

const initialState = {
	status: 'INIT',
	voiceData: null,
	conversationId: null,
	transcript: [],
	isLoading: true,
	error: null,
	containerClient: null,
};

function interviewerReducer(state, action) {
	const { type, payload } = action;

	switch (type) {
		case ActionTypes.START_RECORDING:
			return { ...state, status: 'RECORDING' };

		case ActionTypes.STOP_RECORDING:
			return { ...state, status: 'IDLE', isLoading: true };

		case ActionTypes.AUDIO_PLAYING:
			return { ...state, status: 'PLAYING', isLoading: false };

		case ActionTypes.AUDIO_FINISHED:
			return { ...state, status: 'FINISHED' };

		case ActionTypes.SET_AUDIO_DATA:
			return {
				...state,
				voiceData: payload.voiceData,
				conversationId: payload.conversationId,
				isLoading: false,
			};

		case ActionTypes.SET_TRANSCRIPT:
			return {
				...state,
				transcript: [
					...state.transcript,
					{
						speaker: payload.speaker,
						text: payload.text,
						timestamp: payload.timestamp,
					},
				],
			};

		case ActionTypes.SET_ERROR:
			return { ...state, error: action.payload, isLoading: false };

		case ActionTypes.SET_CONTAINER_CLIENT:
			return { ...state, containerClient: payload };

		case ActionTypes.RESTART_INTERVIEW:
			return {
				...state,
				status: 'PLAYING',
				transcript: initialState.transcript,
				error: initialState.error,
			};

		default:
			return state;
	}
}

export function InterviewerProvider({ children }) {
	const [state, dispatch] = useReducer(interviewerReducer, initialState);

	return (
		<InterviewerContext.Provider value={{ state, dispatch }}>
			{children}
		</InterviewerContext.Provider>
	);
}

export function useInterviewer() {
	const context = useContext(InterviewerContext);
	if (!context) {
		throw new Error('useInterviewer must be used within a InterviewerProvider');
	}
	return context;
}
