export default function PlayArrow({ className }) {
	return (
		<svg
			fill='currentColor'
			stroke='currentColor'
			viewBox='0 0 24 24'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path d='M8.286 3.407A1.5 1.5 0 0 0 6 4.684v14.632a1.5 1.5 0 0 0 2.286 1.277l11.888-7.316a1.5 1.5 0 0 0 0-2.555L8.286 3.407z' />
		</svg>
	);
}
