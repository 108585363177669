import { useCallback, useEffect, useRef, useState } from 'react';
import {
	PlayArrow,
	Mute,
	Download,
	Text,
	Return,
	Microphone,
	Pause,
} from '../components/icons';
import Tippy from '@tippyjs/react';
import { cn } from '../utils';
import { ActionTypes, useInterviewer } from '../context/InterviewerContext';

const styles = {
	controlBar: `flex flex-col absolute top-2/3 w-11/12 md:w-1/2 m-auto left-0 right-0`,
	iconBar: `flex flex-row space-x-4 place-content-center`,
	iconWrapper: `mt-4`,
	icon: `flex self-center w-7 h-7 rounded-md p-1.5 border border-gray-400 mx-2 text-[#ffffff]`,
	activeIcon: `bg-pink-600`,
	textBar: `w-full flex flex-row relative mt-4 `,
	textInput:
		'w-full rounded-md bg-[#FFFFFF] text-[#000000] placeholder:text-black text-base pl-3 pr-10 pt-0.5 h-8',
	returnIcon: `h-5 w-5 absolute top-2 right-4 text-[#000000]`,
	microphoneButton: `w-14 h-14 rounded-full flex self-center justify-center mt-6`,
	microphoneIcon: `w-8 h-8 text-[#ffffff] flex self-center`,
	loader: `w-10 h-10`,
};

export default function ControlBar({
	onToggleRecording,
	onDownloadTranscript,
	pauseAudio,
	playAudio,
	muteAudio,
	unMuteAudio,
	sendTextResponseAndPlay,
	loading,
}) {
	const [activeIcons, setActiveIcons] = useState([]);
	const [inputText, setInputText] = useState('');
	const {
		dispatch,
		state: { status },
	} = useInterviewer();

	const handleIconClick = (iconName) => {
		setActiveIcons((prevIcons) => {
			if (prevIcons.includes(iconName)) {
				return prevIcons.filter((icon) => icon !== iconName);
			} else {
				return [...prevIcons, iconName];
			}
		});
	};

	const handleTextClick = () => {
		handleIconClick('Text');
	};

	const handlePlayClick = () => {
		if (activeIcons.includes('Play')) {
			playAudio();
		} else {
			pauseAudio();
		}
		handleIconClick('Play');
	};
	const handleMuteClick = () => {
		if (activeIcons.includes('Mute')) {
			unMuteAudio();
		} else {
			muteAudio();
		}
		handleIconClick('Mute');
	};

	const handleDownloadClick = () => {
		onDownloadTranscript();
	};

	const handleInputChange = (e) => {
		setInputText(e.target.value);
	};

	const handleSendMessage = useCallback(
		(inputText) => {
			dispatch({ type: ActionTypes.STOP_RECORDING, payload: true });
			sendTextResponseAndPlay(inputText)
				.then(() => {
					setInputText('');
				})
				.catch((error) => {
					console.error('Error sending message:', error);
				});
		},
		[dispatch, sendTextResponseAndPlay]
	);

	const textareaRef = useRef(null);

	useEffect(() => {
		adjustTextareaHeight();
	}, [inputText]);

	const adjustTextareaHeight = () => {
		if (textareaRef.current) {
			textareaRef.current.style.height = '30px';
			textareaRef.current.style.height = `${Math.min(
				textareaRef.current.scrollHeight,
				5 * parseInt(window.getComputedStyle(textareaRef.current).lineHeight)
			)}px`;
			textareaRef.current.rows = Math.max(
				1,
				Math.min(
					5,
					Math.ceil(
						textareaRef.current.scrollHeight /
							parseInt(window.getComputedStyle(textareaRef.current).lineHeight)
					)
				)
			);
		}
	};

	useEffect(() => {
		const handleKeyDown = (event) => {
			if (
				(event.code === 'Space' && !activeIcons.includes('Text')) ||
				(event.code === 'Enter' && activeIcons.includes('Text'))
			) {
				if (event.code === 'Space') {
					onToggleRecording();
				} else if (event.code === 'Enter') {
					handleSendMessage(inputText);
				}
			}
		};

		document.addEventListener('keydown', handleKeyDown);

		return () => {
			document.removeEventListener('keydown', handleKeyDown);
		};
	}, [onToggleRecording, activeIcons, handleSendMessage, inputText]);

	return (
		<div className={styles.controlBar}>
			<div className={styles.iconBar}>
				{/* <div className={styles.iconWrapper} onClick={handlePlayClick} >
					{!activeIcons.includes('Play') ? (
						<Pause className={styles.icon} />
					) : (
						<PlayArrow className={`${styles.icon} ${styles.activeIcon}`} />
					)}
				</div>
				<div className={styles.iconWrapper} onClick={handleMuteClick} >
					<Mute
						className={`${styles.icon} ${
							activeIcons.includes('Mute') ? styles.activeIcon : ''
						}`}
					/>
				</div> */}
				<div className={styles.iconWrapper} onClick={handleDownloadClick} >
					<Download className={styles.icon} />
				</div>
				{/* <div className={styles.iconWrapper} onClick={handleTextClick} >
					<Text
						className={`${styles.icon} ${
							activeIcons.includes('Text') ? styles.activeIcon : ''
						}`}
					/>
				</div> */}
			</div>
			{activeIcons.includes('Text') ? (
				<div className={styles.textBar}>
					<textarea
						ref={textareaRef}
						className={styles.textInput}
						name='inputText'
						value={inputText}
						placeholder='Ask me anything or use the buttons below'
						onChange={handleInputChange}
						disabled={loading || status === 'PLAYING'}
					/>
					<button onClick={() => handleSendMessage(inputText)}>
						<Return className={styles.returnIcon} />
					</button>
				</div>
			) : (
				<Tippy
					content={
						status === 'RECORDING'
							? 'Click to stop recording.'
							: 'Click to start recording.'
					}
				>
					<button
						onClick={onToggleRecording}
						className={cn(styles.microphoneButton, {
							'text-white bg-[#FF0782] animate-ping': status === 'RECORDING',
							'text-black bg-[#FF0782]': status !== 'RECORDING',
							'text-black bg-[#808080]': status === 'PLAYING',
						})}
						disabled={'PLAYING'.includes(status)}
					>
						<Microphone className={styles.microphoneIcon} />
					</button>
				</Tippy>
			)}
		</div>
	);
}
