export default function Text({ className }) {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 24 24'
			fill='currentColor'
			className={className}
		>
			<path
				d='M12 3V21M9 21H15M19 6V3H5V6'
				stroke='currentColor'
				strokeWidth='2'
			/>
		</svg>
	);
}
