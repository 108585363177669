import { useEffect, useRef } from 'react';
import { ActionTypes, useInterviewer } from '../context/InterviewerContext';
import { useToast } from './useToast';
import { visualizeAudio } from '../utils/audioProcessing';
import { useTranslation } from 'react-i18next';

export function useAudioControl(threeComponents) {
	const { t } = useTranslation('global');
	const {
		state: { isLoading },
		dispatch,
	} = useInterviewer();
	const toast = useToast();

	const audioRef = useRef(new Audio());

	const setupAnalyser = () => {
		const audioContext = new AudioContext();
		const src = audioContext.createMediaElementSource(audioRef.current);
		const analyser = audioContext.createAnalyser();
		src.connect(analyser);
		analyser.fftSize = 512;
		analyser.connect(audioContext.destination);

		return analyser;
	};

	const playAudio = (audioUrl) => {
		if (!audioRef.current) {
			console.error('audioRef is not available');
			return;
		}

		console.log('audioRef is available');

		// Revoke the old URL
		URL.revokeObjectURL(audioRef.current.src);

		audioRef.current.playbackRate = 0.5;
		audioRef.current.src = audioUrl;
		console.log('audioUrl:', audioUrl); // Added log

		audioRef.current
			.play()
			.then(() => {
				dispatch({ type: ActionTypes.AUDIO_PLAYING });

				const analyser = setupAnalyser();
				visualizeAudio(isLoading, analyser, threeComponents);
			})
			.catch((e) => {
				console.error('Error playing audio:', e); // Uncomment to log errors
			});
	};

	const pauseAudio = () => {
		if (audioRef.current && !audioRef.current.paused) {
			audioRef.current.pause();
		}
	};

	const playCurrentAudio = () => {
		if (audioRef.current && audioRef.current.paused) {
			audioRef.current.play();
		}
	};

	const muteAudio = () => {
		if (audioRef.current) {
			audioRef.current.volume = 0;
		}
	};

	const unMuteAudio = () => {
		if (audioRef.current) {
			audioRef.current.volume = 1;
		}
	};

	useEffect(() => {
		const audio = audioRef.current;

		const handleAudioEnd = () => {
			dispatch({ type: ActionTypes.AUDIO_FINISHED });
		};

		const handleError = (e) => {
			dispatch({ type: ActionTypes.SET_ERROR, payload: e.message });
			toast.error({
				title: t('useAudioControl.Error'),
				content: e.message,
			});
		};

		if (audio) {
			audio.addEventListener('ended', handleAudioEnd);
			audio.addEventListener('error', handleError);
		}

		return () => {
			if (audio) {
				URL.revokeObjectURL(audio.src);
				audio.removeEventListener('ended', handleAudioEnd);
				audio.removeEventListener('error', handleError);
			}
		};
	}, [audioRef, dispatch, toast]);

	return { playAudio, pauseAudio, playCurrentAudio, muteAudio, unMuteAudio };
}
