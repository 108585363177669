export default function Success({ className }) {
  return (
    <svg
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 511.755 511.755"
      className={className}
    >
      <path d="M436.891 74.867c-99.819-99.819-262.208-99.819-362.027 0-99.819 99.797-99.819 262.229 0 362.027 49.899 49.92 115.456 74.859 181.013 74.859s131.093-24.939 181.013-74.859c99.819-99.798 99.819-262.23.001-362.027zM398.96 185.629 249.627 334.963a21.275 21.275 0 0 1-15.083 6.251 21.277 21.277 0 0 1-15.083-6.251l-85.333-85.333c-8.341-8.341-8.341-21.824 0-30.165 8.341-8.341 21.824-8.341 30.165 0l70.251 70.251 134.251-134.251c8.341-8.341 21.824-8.341 30.165 0 8.341 8.34 8.341 21.823 0 30.164z" />
    </svg>
  );
}
