import { jsPDF } from 'jspdf';
import { HOME_DOMAIN } from './constants';

const FONT_SIZE = 12;
const MARGIN = 1;
const NAME_COLUMN_WIDTH = 1.5;
const LINE_HEIGHT = 0.2;
const SPACING_AFTER_HEADER = 0.5;
const SPACING_AFTER_TITLE = 0.25;

const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const downloadText = (transcript, toast) => {
  try {
    const doc = new jsPDF({
      unit: 'in',
      format: 'a4',
      lineHeight: 1.2,
    });

    doc.setFont('helvetica').setFontSize(FONT_SIZE);

    let currentYPosition = MARGIN;
    doc.setFont('helvetica', 'bold'); // Set to bold Helvetica
    doc.text('Interview Transcript', MARGIN, currentYPosition);
    currentYPosition += SPACING_AFTER_TITLE;

    doc.setFont('helvetica', 'normal'); // Set back to normal Helvetica
    doc.text(
      `Interview Date: ${formatDate(new Date())}`,
      MARGIN,
      currentYPosition
    );
    currentYPosition += SPACING_AFTER_TITLE;

    const participants = Array.from(
      new Set(transcript.map((entry) => entry.speaker))
    )
      .join(', ')
      .replace(' ,', ',');
    doc.text(`Participants: ${participants}.`, MARGIN, currentYPosition);
    currentYPosition += SPACING_AFTER_HEADER;

    const responseColumnStart = MARGIN + NAME_COLUMN_WIDTH;
    const responseColumnWidth =
      doc.internal.pageSize.width - MARGIN * 2 - NAME_COLUMN_WIDTH;

    transcript.forEach((entry) => {
      doc.text(entry.speaker, MARGIN, currentYPosition);
      const responseLines = doc.splitTextToSize(
        entry.text,
        responseColumnWidth
      );
      responseLines.forEach((line, index) => {
        if (
          index > 0 ||
          currentYPosition > doc.internal.pageSize.height - MARGIN
        ) {
          currentYPosition += LINE_HEIGHT;
          if (currentYPosition > doc.internal.pageSize.height - MARGIN) {
            doc.addPage();
            currentYPosition = MARGIN;
          }
        }
        doc.text(line, responseColumnStart, currentYPosition);
      });
      currentYPosition += LINE_HEIGHT * 2; // Double line height for separation between entries
    });

    const addFooter = () => {
      const numberOfPages = doc.internal.getNumberOfPages();
      for (let i = 1; i <= numberOfPages; i++) {
        doc.setPage(i);
        doc.setFont('helvetica', 'bold'); // Bold for page numbers
        doc.text(
          `Page ${i} of ${numberOfPages}`,
          doc.internal.pageSize.width - MARGIN,
          doc.internal.pageSize.height - 0.5,
          { align: 'right' }
        );
        doc.setFont('helvetica', 'normal'); // Normal for website URL
        doc.text(
          HOME_DOMAIN,
          doc.internal.pageSize.width / 2,
          doc.internal.pageSize.height - 0.5,
          { align: 'center' }
        );
      }
    };

    addFooter();
    doc.save('transcript.pdf');
  } catch (error) {
    console.error('An error occurred during PDF generation:', error);
    toast.error({
      title: 'Error',
      content: 'An error occurred during PDF generation.',
    });
  }
};

// Old
// export const downloadText = (transcript) => {
//   const doc = new jsPDF({
//     unit: 'in',
//     format: 'a4',
//     lineHeight: 1.2,
//   });

//   doc.setFont('helvetica').setFontSize(12);
//   const margin = 1;
//   const maxLineWidth = doc.internal.pageSize.width - margin * 2;
//   const nameColumnWidth = 1.5;
//   const responseColumnStart = margin + nameColumnWidth;
//   const responseColumnWidth = maxLineWidth - nameColumnWidth;

//   const formatDate = (date) => {
//     const day = String(date.getDate()).padStart(2, '0');
//     const month = String(date.getMonth() + 1).padStart(2, '0');
//     const year = date.getFullYear();
//     return `${year}-${month}-${day}`;
//   };

//   let currentYPosition = margin;

//   doc.setFont('helvetica', 'bold');
//   doc.text('Interview Transcript', margin, currentYPosition);
//   currentYPosition += 0.25;

//   // Reset font to normal for the rest of the text
//   doc.setFont('helvetica', 'normal');
//   doc.text(
//     `Interview Date: ${formatDate(new Date())}`,
//     margin,
//     currentYPosition
//   );
//   currentYPosition += 0.25;
//   doc.text(
//     `Participants: ${Array.from(
//       new Set(transcript.map((entry) => entry.speaker))
//     )
//       .join(', ')
//       .replace(' ,', ',')
//       .replace('Interviewer', 'Interviewer.')}`,
//     margin,
//     currentYPosition
//   );
//   currentYPosition += 0.5;

//   transcript.forEach((entry) => {
//     // Speaker's name
//     doc.text(entry.speaker, margin, currentYPosition);

//     // Speaker's response
//     const responseLines = doc.splitTextToSize(entry.text, responseColumnWidth);
//     responseLines.forEach((line, index) => {
//       if (
//         index > 0 ||
//         currentYPosition > doc.internal.pageSize.height - margin
//       ) {
//         currentYPosition += 0.2; // Line height
//         if (currentYPosition > doc.internal.pageSize.height - margin) {
//           doc.addPage();
//           currentYPosition = margin;
//         }
//       }
//       doc.text(line, responseColumnStart, currentYPosition);
//     });

//     // Update Y-position for next entry
//     currentYPosition += 0.4;
//     if (currentYPosition > doc.internal.pageSize.height - margin) {
//       doc.addPage();
//       currentYPosition = margin;
//     }
//   });

//   const addFooter = () => {
//     const numberOfPages = doc.internal.getNumberOfPages();

//     for (let i = 1; i <= numberOfPages; i++) {
//       doc.setPage(i);
//       // Page number (bold)
//       doc.setFont('helvetica', 'bold');
//       doc.text(
//         `Page ${i} of ${numberOfPages}`,
//         doc.internal.pageSize.width - margin,
//         doc.internal.pageSize.height - 0.5,
//         { align: 'right' }
//       );
//       // Website URL (normal)
//       doc.setFont('helvetica', 'normal');
//       doc.text(
//         'www.transcript.com',
//         doc.internal.pageSize.width / 2,
//         doc.internal.pageSize.height - 0.5,
//         { align: 'center' }
//       );
//     }
//   };

//   addFooter();

//   doc.save('transcript.pdf');
// };
