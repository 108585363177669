import { ActionTypes } from '../context/InterviewerContext';
import { checkResumeAudioContext } from '../pages/checkResumeAudioContext';
import { makeRoughBall, modulate, max } from './helpers';

export const initializeMediaSource = (mediaSourceRefs) => {
	if (
		!mediaSourceRefs.current.mediaSource ||
		mediaSourceRefs.current.mediaSource.readyState === 'ended'
	) {
		mediaSourceRefs.current.mediaSource = new MediaSource();
	}

	URL.revokeObjectURL(mediaSourceRefs.current.sourceUrl);
	mediaSourceRefs.current.sourceUrl = URL.createObjectURL(
		mediaSourceRefs.current.mediaSource
	);
};

export const cleanupMediaSource = (mediaSourceRefs) => {
	if (mediaSourceRefs.current.mediaSource?.readyState === 'open') {
		mediaSourceRefs.current.mediaSource.endOfStream();
	}
	URL.revokeObjectURL(mediaSourceRefs.current.sourceUrl);
};

export const processChunksMediaSource = async (
	mediaSourceRefs,
	textToSpeechConversion,
	answer,
	dispatch
) => {
	let audioChunksQueue = [];
	let allChunksAppended = false;

	mediaSourceRefs.current.mediaSource.addEventListener(
		'sourceopen',
		async () => {
			const sourceBuffer =
				mediaSourceRefs.current.mediaSource.addSourceBuffer('audio/mpeg');

			sourceBuffer.addEventListener('updateend', () => {
				if (audioChunksQueue.length > 0 && !sourceBuffer.updating) {
					try {
						const nextChunk = audioChunksQueue.shift();
						sourceBuffer.appendBuffer(nextChunk);
					} catch (e) {
						console.error('Error sending message:', e);
						dispatch({ type: ActionTypes.RESTART_INTERVIEW });
					}
				} else if (allChunksAppended && !sourceBuffer.updating) {
					mediaSourceRefs.current.mediaSource.endOfStream();
				}
			});

			await textToSpeechConversion(
				answer,
				(chunk) => {
					if (chunk) {
						if (!sourceBuffer.updating) {
							try {
								sourceBuffer.appendBuffer(chunk);
							} catch (e) {
								console.error('Error sending message:', e);
								dispatch({ type: ActionTypes.RESTART_INTERVIEW });
							}
						} else {
							audioChunksQueue.push(chunk);
						}
					} else {
						allChunksAppended = true;
					}
				},
				mediaSourceRefs.current.type
			);
		}
	);
};

export const setupAudioContext = (audioContextRefs) => {
	initializeAudioContext(audioContextRefs);
	resetAudioContextVariables(audioContextRefs);
	return;
};

// Initialize AudioContext
export const initializeAudioContext = (audioContextRefs) => {
	checkResumeAudioContext(audioContextRefs)
};

// Reset Variables
export const resetAudioContextVariables = (audioContextRefs) => {
	audioContextRefs.current.audioChunkQueue = [];
	audioContextRefs.current.isPlaying = false;
	audioContextRefs.current.lastBufferEndTime = 0;
	audioContextRefs.current.endOfStreamReached = false;

	return;
};

// Cleanup AudioContext
export const cleanupAudioContext = (audioContextRefs) => {
	const { audioContext } = audioContextRefs.current;

	if (audioContext && audioContext.state !== 'closed') {
		audioContext.close();
	}

	// Optionally reset other properties
	audioContextRefs.current.audioChunkQueue = [];
	audioContextRefs.current.isPlaying = false;
	audioContextRefs.current.lastBufferEndTime = 0;
	audioContextRefs.current.endOfStreamReached = false;
	audioContextRefs.current.analyser = null; // Reset analyser
};

export const enqueueChunk = (chunk, isFinalChunk = false, props) => {
	const { audioContextRefs } = props;
	const { audioChunkQueue, isPlaying } = audioContextRefs.current;

	if (!isFinalChunk) {
		audioChunkQueue.push(new Blob([chunk], { type: 'audio/mpeg' }));
	} else {
		audioContextRefs.current.endOfStreamReached = true;
	}

	if (!isPlaying && audioContextRefs.current.endOfStreamReached) {
		processAudioChunks(props);
	}
};

const processAudioChunks = async (props) => {
	const { toast, dispatch, audioContextRefs, threeComponents } = props;
	const {
		audioContext,
		analyser,
		audioChunkQueue,
		isPlaying,
		lastBufferEndTime,
		offset,
	} = audioContextRefs.current;

	if (audioChunkQueue.length > 0 && !isPlaying) {
		audioContextRefs.current.isPlaying = true;

		// Combine all blobs into a single blob
		const combinedBlob = new Blob(audioChunkQueue, { type: 'audio/mpeg' });
		audioContextRefs.current.audioChunkQueue = []; // Clear the array

		try {
			const arrayBuffer = await combinedBlob.arrayBuffer();
			const audioData = await audioContext.decodeAudioData(arrayBuffer);
			const source = audioContext.createBufferSource();
			source.buffer = audioData;
			source.connect(analyser);
			analyser.connect(audioContext.destination);
			
			// Calculate the start time with offset
			const startTime = Math.max(0, lastBufferEndTime - offset);
			source.start(startTime);
			dispatch({ type: ActionTypes.AUDIO_PLAYING });
			
			visualizeAudio(false, analyser, threeComponents);
			
			audioContextRefs.current.lastBufferEndTime =
					startTime + audioData.duration; // Update the end time
			
			source.onended = () => {
				props.audioContextRefs.current.isPlaying = false;

				const { audioChunkQueue, endOfStreamReached } =
					props.audioContextRefs.current;

				if (audioChunkQueue.length > 0) {
					processAudioChunks(props); // Continue processing
				} else if (endOfStreamReached) {
					dispatch({ type: ActionTypes.AUDIO_FINISHED });
				}
			};

			source.onerror = (e) => {
				toast.error({
					title: 'Error',
					content: e.message,
				});
			};
		} catch (error) {
			console.error('Error decoding audio data:', error);
		}
	} else {
		dispatch({ type: ActionTypes.AUDIO_FINISHED });
	}
};

export const visualizeAudio = (isLoading, analyser, threeComponents) => {
	const { sphere, renderer, scene, camera, group } = threeComponents;

	const bufferLength = analyser.frequencyBinCount;
	const dataArray = new Uint8Array(bufferLength);

	const render = () => {
		if (!analyser || !sphere || !renderer) return;

		analyser.getByteFrequencyData(dataArray);
		const lowerHalfArray = dataArray.slice(0, dataArray.length / 2 - 1);
		const lowerMax = max(lowerHalfArray);
		const lowerMaxFr = lowerMax / lowerHalfArray.length;

		makeRoughBall(
			sphere,
			modulate(Math.pow(lowerMaxFr, 0.8), 0, 1, 0, 8),
			isLoading
		);
		group.rotation.y += 0.001;
		renderer.render(scene, camera);
		requestAnimationFrame(render);
	};
	render();
};