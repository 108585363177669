import { useCallback, useEffect} from 'react';

import { BlobServiceClient } from '@azure/storage-blob';
import { useInterviewer, ActionTypes } from '../context/InterviewerContext';

import { Button } from '../components/ui';
import { Reset } from '../components/icons';

import { useAudioControl } from '../hooks/useAudioControl';
import { useThreeScene } from '../hooks/useThreeScene';
import { useFetchAudio } from '../hooks/useFetchAudio';
import { useRecord } from '../hooks/useRecord';
import { useToast } from '../hooks/useToast';

import { downloadText } from '../utils/pdfService';

import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import ControlBar from '../components/ControlBar';
import { useTranslation } from 'react-i18next';
import NewLoader from '../components/ui/NewLoader';
import { checkResumeAudioContext } from './checkResumeAudioContext';

const styles = {
	contentContainer: `absolute inset-0 transition duration-300 ease-out overflow-hidden`,
	blobCanvas: `w-full h-full`,
	mainButton: `text-lg font-medium cursor-pointer absolute bottom-36 lg:bottom-40 left-1/2 transform -translate-x-1/2 text-white w-60 sm:w-full sm:max-w-sm`,
	downloadButton: `absolute bottom-12 left-1/2 transform -translate-x-1/2 text-white hover:text-pink-500 transition-colors duration-300 ease-out`,
	downloadIcon: `w-8 h-8`,
	resetButton: `absolute w-10 bottom-24 md:bottom-32 left-1/2 transform -translate-x-1/2 cursor-pointer text-white p-2 bg-pink-500 rounded-full`,
	resetIcon: `h-6 w-6`,
	microphoneButton: `animate-fade-in absolute bottom-14 md:bottom-28 left-1/2 transform -translate-x-1/2 p-1 rounded-full transition-colors duration-300 ease-out`,
	microphoneIcon: `w-8 h-8`,
	loadingIcon: `absolute top-2/3 w-full flex justify-center`,
};
const account = process.env.REACT_APP_STORAGE_ACCOUNT;
const sasToken =
	'?sv=2022-11-02&ss=bfqt&srt=sco&sp=rwdlacupiytfx&se=2025-07-12T21:31:16Z&st=2023-12-19T14:31:16Z&spr=https&sig=gJ4vt%2BRPPJopdomIt31FkrCSha8aUqGmgKwl6vh6XYo%3D'; // get the SAS token from the .env file
const containerName = 'interviewer-dt-blob-storage';

export default function Interviewer({ type }) {
	const { t } = useTranslation('global');
	const toast = useToast();

	const {
		state: { status, isLoading, conversationId, transcript, voiceData, error },
		dispatch,
	} = useInterviewer();

	useFetchAudio(type);

	const { containerRef, ...threeComponents } = useThreeScene({
		includeStars: true,
		startStationary: true,
		type,
	});

	const { playAudio, pauseAudio, playCurrentAudio, muteAudio, unMuteAudio } =
		useAudioControl(threeComponents);
	const {
		startRecording,
		stopRecording,
		sendTextResponseAndPlay,
		loading,
		isBufferFailed,
		setIsBufferFailed,
		audioContextRefs
	} = useRecord(type, playAudio, threeComponents);

	const toggleRecording = () => {
		if (status === 'RECORDING') {

			checkResumeAudioContext(audioContextRefs)

			dispatch({ type: ActionTypes.STOP_RECORDING });
			stopRecording();

		} else {
			dispatch({ type: ActionTypes.START_RECORDING });
			pauseAudio(); // Not really needed.
			startRecording();
		}
	};

	const handleRestart = () => {
		dispatch({ type: ActionTypes.RESTART_INTERVIEW });
		pauseAudio();
		playDefault();
		setIsBufferFailed(false);
	};

	const playDefault = useCallback(() => {
		const audioData = new Blob([voiceData], { type: 'audio/mpeg' });
		const url = URL.createObjectURL(audioData);
		playAudio(url);
	}, [voiceData, playAudio]);

	useEffect(() => {
		// Make sure these variables are defined in .env
		const blobServiceClient = new BlobServiceClient(
			`https://${account}.blob.core.windows.net/?${sasToken}`
		);

		const containerClient = blobServiceClient.getContainerClient(containerName);

		dispatch({
			type: ActionTypes.SET_CONTAINER_CLIENT,
			payload: containerClient,
		});

	}, [dispatch]);

	const downloadTranscript = () => {
		downloadText(transcript, toast);
	};

	useEffect(() => {
		if (status === 'INIT' && conversationId) {
			const handleKeyDown = (event) => {
				if (event.code === 'Space') {
					playDefault();
				}
			};

			document.addEventListener('keydown', handleKeyDown);

			return () => {
				document.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [status, conversationId, playDefault]);

	useEffect(() => {
		if (error && !isLoading) {
			const handleKeyDown = (event) => {
				if (event.code === 'Space') {
					handleRestart();
				}
			};

			document.addEventListener('keydown', handleKeyDown);

			return () => {
				document.removeEventListener('keydown', handleKeyDown);
			};
		}
	}, [error, isLoading, handleRestart]);

	return (
		<div className={styles.contentContainer}>
			{/* Blob */}
			<div
				ref={containerRef}
				className={styles.blobCanvas}
			/>

			{status === 'INIT' && conversationId && (
					<>
						<Button
							type='button'
							onClick={playDefault}
							blur={10}
							disabled={!conversationId || !voiceData}
							className={styles.mainButton}
							on
						>
							{t('interviewer.AskMeAnything')}
						</Button>
					</>
				)}

			{['FINISHED', 'RECORDING', 'PLAYING'].includes(status) && !error && (
					<ControlBar
						onDownloadTranscript={downloadTranscript}
						onToggleRecording={toggleRecording}
						type={type}
						pauseAudio={pauseAudio}
						playAudio={playCurrentAudio}
						muteAudio={muteAudio}
						unMuteAudio={unMuteAudio}
						sendTextResponseAndPlay={sendTextResponseAndPlay}
						loading={loading}
					/>
				)}

			{isLoading && !isBufferFailed &&(
					<div className={styles.loadingIcon}>
						<NewLoader />
					</div>
				)}

			{((error && !isLoading) || isBufferFailed) && (
				<Tippy content={t('interviewer.ClickToReset')}>
					<button
						onClick={handleRestart}
						className={styles.resetButton}
					>
						<Reset className={styles.resetIcon} />
					</button>
				</Tippy>
			)}
		</div>
	);
}