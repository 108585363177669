import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import global_en from './locales/en/global.json';
import global_es from './locales/es/global.json';

const root = ReactDOM.createRoot(document.getElementById('root'));
i18next.init({
	interpolation: { escapeValue: false },
	lng: 'auto',
	fallbackLng: 'en',
	resources: {
		en: {
			global: global_en,
		},
		es: {
			global: global_es,
		},
	},
});

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.debug = () => {};
	console.info = () => {};
	console.warn = () => {};
	console.error = () => {};
}

root.render(
	// <React.StrictMode>
	<I18nextProvider i18n={i18next}>
		<App />
	</I18nextProvider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
