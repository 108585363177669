import { cn } from '../../utils';
import { Link } from 'react-router-dom';

import Loader from './Loader';

const styles = {
  link: `h-14 bg-transparent flex justify-center items-center border border-pink-500 text-pink-500 p-4 rounded-xl hover:opacity-60 opacity-transition`,
  button: `relative h-14 w-full bg-pink-500 flex justify-center items-center text-white font-medium text-lg disabled:opacity-40 hover:opacity-60 opacity-transition rounded-xl disabled:cursor-default`,
  buttonBlur: `absolute inset-0 bg-pink-500 pointer-events-none`,
  loadingIcon: `h-10 w-10 border-white`,
};

export default function Button({
  children,
  onClick,
  disabled,
  href,
  type,
  className,
  isLoading = false,
  blur,
  loaderClassName,
}) {
  if (href) {
    return (
      <Link to={href} className={cn(styles.link, className)}>
        {children}
      </Link>
    );
  } else {
    return (
      <button
        type={type}
        onClick={onClick}
        disabled={disabled}
        className={cn(styles.button, className)}
      >
        <div className={blur && 'z-10'}>
          {isLoading ? (
            <div className="pr-2">
              <Loader
                className={cn(styles.loadingIcon, loaderClassName)}
                borderWidth={5}
              />
            </div>
          ) : (
            children
          )}
        </div>

        {blur && (
          <div
            style={{ filter: `blur(${blur}px)` }}
            className={cn(styles.buttonBlur)}
          ></div>
        )}
      </button>
    );
  }
}
