export const checkResumeAudioContext = async (audioContextRefs) => {
  const canUseMediaSource =
			window.MediaSource && MediaSource.isTypeSupported('audio/mpeg');

  if (
    !canUseMediaSource &&
    (!audioContextRefs.current.audioContext ||
    audioContextRefs.current.audioContext.state === 'closed')
  ) {
    audioContextRefs.current.audioContext = new AudioContext();
    audioContextRefs.current.analyser =
    audioContextRefs.current.audioContext.createAnalyser();
    audioContextRefs.current.analyser.fftSize = 512;
  }

  if (!canUseMediaSource && audioContextRefs.current.audioContext.state === 'suspended') {
    await audioContextRefs.current.audioContext.resume();
  }
}