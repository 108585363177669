import React from 'react';

export default function NewLoader() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			viewBox='0 0 100 100'
			width='100'
			height='100'
			transform='scale(-1, 1)'
		>
			<radialGradient
				id='a12'
				cx='.66'
				fx='.66'
				cy='.3125'
				fy='.3125'
				gradientTransform='scale(1.5)'
			>
				<stop
					offset='0'
					stopColor='#FFFFFF'
				></stop>
				<stop
					offset='.3'
					stopColor='#FFFFFF'
					stopOpacity='.9'
				></stop>
				<stop
					offset='.6'
					stopColor='#FFFFFF'
					stopOpacity='.6'
				></stop>
				<stop
					offset='.8'
					stopColor='#FFFFFF'
					stopOpacity='.3'
				></stop>
				<stop
					offset='1'
					stopColor='#FFFFFF'
					stopOpacity='0'
				></stop>
			</radialGradient>
			<circle
				transform-origin='center'
				fill='none'
				stroke='url(#a12)'
				strokeWidth='8'
				strokeLinecap='round'
				strokeDasharray='100 500'
				strokeDashoffset='0'
				cx='50'
				cy='50'
				r='35'
			>
				<animateTransform
					type='rotate'
					attributeName='transform'
					calcMode='spline'
					dur='2'
					values='360;0'
					keyTimes='0;1'
					keySplines='0 0 1 1'
					repeatCount='indefinite'
				></animateTransform>
			</circle>
			<circle
				transform-origin='center'
				fill='none'
				opacity='.2'
				stroke='#55184C'
				strokeWidth='8'
				strokeLinecap='round'
				cx='50'
				cy='50'
				r='35'
			></circle>
		</svg>
	);
}
