import { useCallback, useEffect, useRef, useState } from 'react';

import { useAudioRecorder } from 'react-audio-voice-recorder';
import { getWaveBlob } from 'webm-to-wav-converter';

import { useInterviewer, ActionTypes } from '../context/InterviewerContext';
import { useToast } from './useToast';

import {
	createAzureSTT,
	sendMessage,
	textToSpeechConversion,
} from '../utils/api';
import { generateTimestamp, measurePerformance } from '../utils/helpers';

import {
	enqueueChunk,
	cleanupAudioContext,
	setupAudioContext,
	cleanupMediaSource,
	initializeMediaSource,
	processChunksMediaSource,
} from '../utils/audioProcessing';
import { INTERVIEWER_TYPE } from '../utils/constants';
import { useTranslation } from 'react-i18next';

const getSpeakerName = (type) => {
	const typeKey = Object.keys(INTERVIEWER_TYPE).find(
		(key) => INTERVIEWER_TYPE[key].id === type
	);
	return INTERVIEWER_TYPE[typeKey]?.title || 'Unknown';
};

export function useRecord(type, playAudio, threeComponents) {
	const { t } = useTranslation('global');
	const { recordingBlob, startRecording, stopRecording } = useAudioRecorder();
	const { dispatch, state, voiceData } = useInterviewer();
	const toast = useToast();
	const [loading, setLoading] = useState(false);

	const { status, conversationId } = state;

	// Media Source
	const mediaSourceRefs = useRef({
		type: type,
		mediaSource: null,
		sourceUrl: '',
	});

	// Audio Context
	const audioContextRefs = useRef({
		audioContext: '',
		analyser: null,
		audioChunkQueue: [],
		isPlaying: false,
		lastBufferEndTime: 0,
		offset: 1.0, // Adjust to test
		endOfStreamReached: false,
	});

	const processRecording = async () => {
		try {
			// save blob to AZ blob
			const audioBlob = await measurePerformance(
				() => getWaveBlob(recordingBlob, false),
				{ name: 'Get-Blob', enableLogging: true }
			);

			const sttResponse = await measurePerformance(
				() => createAzureSTT(audioBlob),
				{ name: 'Azure-STT', enableLogging: true }
			);

			dispatch({
				type: ActionTypes.SET_TRANSCRIPT,
				payload: {
					speaker: process.env.REACT_APP_FULL_NAME,
					text: sttResponse.data.DisplayText,
					timestamp: generateTimestamp(),
				},
			});
			await sendResponseAndPlay(sttResponse, audioBlob);
		} catch (error) {
			dispatch({ type: ActionTypes.SET_ERROR, payload: error.message });
			toast.error({
				title: t('useRecord.Error'),
				content: t('useRecord.AnError'),
			});
			console.error(error);
		}
	};

	const playDefault = useCallback(() => {
		const audioData = new Blob([voiceData], { type: 'audio/mpeg' });
		const url = URL.createObjectURL(audioData);
		playAudio(url);
	}, [voiceData, playAudio]);

	const sendResponseAndPlay = async (sttResponse) => {
		const messageResponse = await measurePerformance(
			() =>
				sendMessage(
					conversationId,
					sttResponse.data.DisplayText,
					type,
					'direct_audio_data'
				),
			{ name: 'Send-message', enableLogging: true }
		);

		const answer = (messageResponse.data.answer || messageResponse.data).trim();

		if (messageResponse.data) {
			const speakerName = getSpeakerName(type);
			dispatch({
				type: ActionTypes.SET_TRANSCRIPT,
				payload: {
					speaker: speakerName,
					text: answer,
					timestamp: generateTimestamp(),
				},
			});

			const canUseMediaSource =
				window.MediaSource && MediaSource.isTypeSupported('audio/mpeg');

			if (canUseMediaSource) {
				// Media Source Method
				initializeMediaSource(mediaSourceRefs);
				await processChunksMediaSource(
					mediaSourceRefs,
					textToSpeechConversion,
					answer,
					dispatch
				);
				playAudio(mediaSourceRefs.current.sourceUrl);
			} else {
				// AudioContext
				setupAudioContext(audioContextRefs);

				await textToSpeechConversion(
					answer,
					async (chunk, isFinal) => {
						enqueueChunk(chunk, isFinal, {
							toast,
							dispatch,
							audioContextRefs,
							threeComponents,
						});
					},
					type
				);
			}
		}
	};

	const sendTextResponseAndPlay = async (inputText) => {
		setLoading(true);
		try {
			const message = inputText;

			const messageResponse = await measurePerformance(
				 () => sendMessage( conversationId, message, type, ''),	
				{ name: 'Send-message', enableLogging: true }
			);

			console.log('Message sent successfully!');

			const answer = (
				messageResponse.data.answer || messageResponse.data
			).trim();

			if (messageResponse.data) {
				const speakerName = getSpeakerName(type);
				dispatch({
					type: ActionTypes.SET_TRANSCRIPT,
					payload: {
						speaker: speakerName,
						text: answer,
						timestamp: generateTimestamp(),
					},
				});

				const canUseMediaSource =
					window.MediaSource && MediaSource.isTypeSupported('audio/mpeg');

				if (canUseMediaSource) {
					initializeMediaSource(mediaSourceRefs);
					await processChunksMediaSource(
						mediaSourceRefs,
						textToSpeechConversion,
						answer,
						dispatch
					);
					playAudio(mediaSourceRefs.current.sourceUrl);
					setLoading(false);
				} else {
					setupAudioContext(audioContextRefs);

					await textToSpeechConversion(
						answer,
						async (chunk, isFinal) => {
							enqueueChunk(chunk, isFinal, {
								toast,
								dispatch,
								audioContextRefs,
								threeComponents,
							});
						},
						type
					);
					setLoading(false);
				}
			}
		} catch (error) {
			console.error('Error sending message:', error);
			dispatch({ type: ActionTypes.RESTART_INTERVIEW });
			playDefault();
			setLoading(false);
		}
	};

	useEffect(() => {
		if (status === 'IDLE' && recordingBlob) processRecording();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recordingBlob]);

	useEffect(() => {
		return () => {
			cleanupMediaSource(mediaSourceRefs); // MediaSource
			cleanupAudioContext(audioContextRefs); // AudioContext
		};
	}, []);

	return { startRecording, stopRecording, sendTextResponseAndPlay, loading, audioContextRefs };
}