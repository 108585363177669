import React, { createContext, useReducer, useContext, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthActions = {
  LOGIN: 'login',
  LOGOUT: 'logout',
  UPDATE_USER: 'update-user',
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case AuthActions.LOGIN:
      return { user: action.payload };
    case AuthActions.LOGOUT:
      return { user: null };
    case AuthActions.UPDATE_USER:
      return { ...state, user: { ...state.user, ...action.payload } };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const initialState = {
    user: JSON.parse(localStorage.getItem('_auth_user')) || null,
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    localStorage.setItem('_auth_user', JSON.stringify(state.user));
  }, [state.user]);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error(
      'useAuthContext must be used inside an AuthContextProvider'
    );
  }
  return context;
};