import axios from 'axios';

export function createConversation(type) {
	return axios({
		url: `${process.env.REACT_APP_API_ENDPOINT}/${type}/create-conversation`,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
			'Access-Control-Allow-Origin': '*',
		},
		data: {
			user_id: process.env.REACT_APP_API_USER_ID,
		},
	});
}

export function textToSpeech(text) {
	return axios({
		url: `${process.env.REACT_APP_API_ELEVEN_LAB}/text-to-speech/${process.env.REACT_APP_API_VOICE_ID}/stream`,
		method: 'post',
		headers: {
			Accept: 'audio/mpeg',
			'Content-Type': 'application/json',
			'xi-api-key': process.env.REACT_APP_API_ELEVEN_LAB_KEY,
		},
		data: {
			text: text,
			model_id: 'eleven_multilingual_v2',
			voice_settings: {
				stability: 0.5,
				similarity_boost: 0.5,
			},
		},
		responseType: 'arraybuffer',
	});
}

export function textToSpeechStream(text) {
	return fetch(
		`${process.env.REACT_APP_API_ELEVEN_LAB}/text-to-speech/${process.env.REACT_APP_API_VOICE_ID}/stream`,
		{
			method: 'POST',
			headers: {
				'xi-api-key': process.env.REACT_APP_API_ELEVEN_LAB_KEY,
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				text: text,
				model_id: 'eleven_multilingual_v2',
				voice_settings: {
					stability: 0.5,
					similarity_boost: 0.5,
				},
			}),
		}
	);
}

function base64ToArrayBuffer(base64) {
	const binaryString = window.atob(base64);
	const len = binaryString.length;
	const bytes = new Uint8Array(len);
	for (let i = 0; i < len; i++) {
		bytes[i] = binaryString.charCodeAt(i);
	}
	return bytes.buffer;
}

export async function textToSpeechConversion(text, appendAudioChunk) {
	return new Promise((resolve, reject) => {
		const model = 'eleven_turbo_v2';
		const wsUrl = `wss://api.elevenlabs.io/v1/text-to-speech/${process.env.REACT_APP_API_VOICE_ID}/stream-input?model_id=${model}`;
		let connectionStartTime = performance.now();
		const socket = new WebSocket(wsUrl);

		// Function to wait until the socket is open
		const waitForOpenSocket = (socket) => {
			return new Promise((resolve) => {
				if (socket.readyState !== socket.OPEN) {
					socket.addEventListener('open', (_) => {
						resolve();
					});
				} else {
					resolve();
				}
			});
		};

		// Function to send a message
		const sendMessage = async (msg) => {
			await waitForOpenSocket(socket);
			socket.send(msg);
		};

		// Handle WebSocket 'open' event
		socket.onopen = () => {
			let connectionEndTime = performance.now();
			console.log(
				`Elevenslab webSocket connection time: ${
					(connectionEndTime - connectionStartTime) / 1000
				} seconds.`
			);
		};

		socket.onmessage = async (event) => {
			const response = JSON.parse(event.data);
			// console.log('Server response:', response);

			if (response.error && response.error === 'quota_exceeded') {
				reject(new Error('Quota exceeded. Please upgrade your plan.'));
				socket.close();
				return;
			}

			if (response.audio) {
				const audioChunk = base64ToArrayBuffer(response.audio);
				appendAudioChunk(audioChunk, false);
			}

			if (response.isFinal) {
				appendAudioChunk(null, true);
				socket.close();
				resolve();
			}
		};

		socket.onerror = (error) => {
			// console.error('WebSocket Error:', error);
			reject(new Error(error));
		};

		socket.onclose = (event) => {
			if (event.wasClean) {
				// console.log(
				//   `Connection closed cleanly, code=${event.code}, reason=${event.reason}`
				// );
			} else {
				// console.log('WebSocket connection closed unexpectedly');
				reject(new Error('WebSocket connection closed unexpectedly'));
			}
		};

		// Send messages
		(async () => {
			const bosMessage = JSON.stringify({
				text: ' ',
				voice_settings: {
					stability: 0.5,
					similarity_boost: 0.5,
				},
				xi_api_key: process.env.REACT_APP_API_ELEVEN_LAB_KEY,
			});

			const textMessage = JSON.stringify({
				text: text,
				try_trigger_generation: true,
			});

			const eosMessage = JSON.stringify({ text: '' });

			await sendMessage(bosMessage);
			await sendMessage(textMessage);
			await sendMessage(eosMessage);
		})();
	});
}

export function createAzureSTT(audio) {
	return axios({
		url: `https://eastus.stt.speech.microsoft.com/speech/recognition/conversation/cognitiveservices/v1?language=en-US`,
		method: 'post',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'audio/wav',
			'Ocp-Apim-Subscription-Key': process.env.REACT_APP_API_AZURE_KEY,
		},
		data: audio,
	});
}

export function createAzureBlob(audio) {
	return axios({
		url: `https://mftqafrontend.blob.core.windows.net/mindfund-qa-blob/${audio}/?sp=racwd&st=2023-10-03T14:25:05Z&se=2026-10-02T22:25:05Z&sv=2022-11-02&sr=c&sig=Ssvh0ZASolM0zMjd4AmhUwrMu1vO%2Fn66sBqY%2B0zcgJk%3D`,
		method: 'put',
		headers: {
			// Authorization:
			// "sv=2022-11-02&ss=b&srt=co&sp=rwdlaciytfx&se=2026-10-03T20:01:52Z&st=2023-10-03T12:01:52Z&spr=https,http&sig=tVZ1WS5ivistOYMQ6Sjm545V8RUQbYwIwfjncHm0WH8%3D",
			'x-ms-date': new Date().getTime(),
			'x-ms-version': '2021-06-08',
			'x-ms-blob-type': 'BlockBlob',
			// "Content-Length": audio.lengthSync().toString(),
			'Access-Control-Allow-Origin': 'http://localhost:3000/',
			'Content-Type': 'audio/wave',
		},
		// data: audio,
	});
}

export function loginAzure() {
	return axios({
		url: `https://login.microsoftonline.com/32460208-064c-4b51-893c-5fa84cece7ae/oauth2/token`,
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Accept: '*',
		},
		data: {
			grant_type: 'client_credentials',
			client_id: '2197df2e-c7f7-4bb7-8239-6fd98dc56d76',
			client_secret: '6Ny8Q~QS9DGZYOcZUVBrO-quHfzZkqUe_XhcAa9Q',
			resource: 'https://storage.azure.com',
		},
	});
}

export function sendMessage(
	conversation_id,
	message,
	type,
	recording_blob_url = ''
) {
	return axios({
		url: `${process.env.REACT_APP_API_ENDPOINT}/${type}/send-message`,
		method: 'post',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
			'Access-Control-Allow-Origin': '*',
		},
		data: {
			conversation_id: conversation_id,
			user_id: process.env.REACT_APP_API_USER_ID,
			message: message,
			recording_blob_url: recording_blob_url,
		},
	});
}

export async function azureTextToSpeechConversion(text) {
	const apiKey = '4591324b60ec4db7b7a4f9da551d0434';
	const region = 'eastus';
	const speechSynthesisVoiceName = 'en-US-JennyNeural';

	return axios.post(
		`https://${region}.tts.speech.microsoft.com/cognitiveservices/v1`,
		`<speak version='1.0' xml:lang='en-US'><voice name='${speechSynthesisVoiceName}'>${text}</voice></speak>`,
		{
			headers: {
				'Content-Type': 'application/ssml+xml',
				'Ocp-Apim-Subscription-Key': apiKey,
				'X-Microsoft-OutputFormat': 'riff-24khz-16bit-mono-pcm',
			},
			responseType: 'arraybuffer',
		}
	);
}
