import React, { useState, useEffect, useRef } from 'react';
import { cn } from '../../utils';

import { collapseToast } from '../../utils/collapseToast';

import { Close, Info, Success, Warning, Error } from '../icons';

const styles = {
  toast: `relative w-full max-w-[400px] sm:w-[400px] flex items-center gap-3 sm:gap-4 mt-3 pl-3 pr-5 py-3 sm:pl-4 sm:pr-6 sm:py-4 border-[.5px] bg-opacity-95 backdrop-blur rounded-xl overflow-hidden pointer-events-auto`,
  toastIconWrapper: `inline-flex h-8 w-8 sm:h-12 sm:w-12 p-1.5 sm:p-2.5 bg-opacity-10 rounded-full shrink-0`,
  toastTextWrapper: `flex flex-col`,
  toastErrorText: `text-base sm:text-lg font-medium tracking-wide`,
  toastErrorDetails: `text-white/60 tracking-normal text-sm sm:text-base`,
  toastCloseButton: `group absolute right-0 p-4`,
  toastCloseIcon: `h-4 w-4 text-white/30 group-hover:text-white/50 smooth-transition`,
};

// Define toast animation for toast entrance and exit
const AnimationStep = {
  Enter: 'animate-fade-in-up sm:animate-bounce-in-left',
  Exit: 'animate-fade-out sm:animate-bounce-out-right',
};

// Define toast type specific styles and icons
const typeToClassNames = {
  info: {
    containerClass: 'bg-info-dark border-info/50',
    backgroundColor: 'bg-info/10',
    textColor: 'text-info',
    Icon: Info,
  },
  success: {
    containerClass: 'bg-success-dark border-success/50',
    backgroundColor: 'bg-success/10',
    textColor: 'text-success',
    Icon: Success,
  },
  warning: {
    containerClass: 'bg-warning-dark border-warning/50',
    backgroundColor: 'bg-warning/10',
    textColor: 'text-warning',
    Icon: Warning,
  },
  error: {
    containerClass: 'bg-error-dark border-error/50',
    backgroundColor: 'bg-error/10',
    textColor: 'text-error',
    Icon: Error,
  },
};

export default function Toast({
  id,
  type,
  title,
  content,
  duration,
  onDismiss,
}) {
  const { containerClass, textColor, backgroundColor, Icon } =
    typeToClassNames[type];

  const toastDuration =
    duration ?? (title.length + (content ? content.length : 0)) * 60;

  const nodeRef = useRef(null);
  const timerIdRef = useRef(null);
  const startTimeRef = useRef(id);
  const remainingTimeRef = useRef(toastDuration);

  const [animation, setAnimation] = useState(AnimationStep.Enter);

  const handleMouseEnter = () => {
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    remainingTimeRef.current -= new Date().getTime() - startTimeRef.current;
  };

  const handleMouseLeave = () => {
    startTimeRef.current = new Date().getTime();

    if (animation !== AnimationStep.Exit) {
      timerIdRef.current = setTimeout(
        () => setAnimation(AnimationStep.Exit),
        remainingTimeRef.current
      );
    }
  };

  const handleAnimationEnd = () => {
    if (animation === AnimationStep.Exit) {
      if (nodeRef.current) {
        collapseToast(nodeRef.current, () => onDismiss(id), 300);
      } else {
        onDismiss(id); // To handle rare scenarios (pre-caution)
      }
    }
  };

  useEffect(() => {
    timerIdRef.current = setTimeout(
      () => setAnimation(AnimationStep.Exit),
      remainingTimeRef.current
    );

    return () => {
      if (timerIdRef.current) clearTimeout(timerIdRef.current);
    };
  }, [id]);

  return (
    <div
      ref={nodeRef}
      className={cn(styles.toast, containerClass, animation)}
      onAnimationEnd={handleAnimationEnd}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      <div className={cn(styles.toastIconWrapper, backgroundColor)}>
        <Icon className={textColor} />
      </div>

      {content ? (
        <div className={styles.toastTextWrapper}>
          <p className={cn(styles.toastErrorText, textColor)}>{title}</p>
          <span className={styles.toastErrorDetails}>{content}</span>
        </div>
      ) : (
        <p className={cn(styles.toastErrorText, textColor)}>{title}</p>
      )}

      <button
        type="button"
        onClick={() => setAnimation(AnimationStep.Exit)}
        className={cn(styles.toastCloseButton, {
          'top-0': content,
          'inset-y-0 flex items-center': !content,
        })}
        aria-label="close"
      >
        <Close className={styles.toastCloseIcon} />
      </button>
    </div>
  );
}
