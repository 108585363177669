import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';

import { HOME_DOMAIN } from './constants';

export const cn = (...inputs) => {
  return twMerge(clsx(inputs));
};

export function constructMetadata(options = {}) {
  const {
    title = 'Mind Bank AI',
    description = 'Welcome to my personal digital twin.',
    image = 'https://app.mindbank.ai/_static/thumbnail.png',
    icons = '/favicon.ico',
    noIndex = false,
  } = options;

  const metadata = {
    title,
    description,
    openGraph: {
      title,
      description,
      images: [
        {
          url: image,
        },
      ],
    },
    icons,
    metadataBase: new URL(`https://${HOME_DOMAIN}/`),
  };

  if (noIndex) {
    metadata.robots = {
      index: false,
      follow: false,
    };
  }

  return metadata;
}
