import { useContext } from 'react';

import { ToastContext } from '../components/providers/ToastProvider';

/**
 * `useToast` is a custom hook that provides an interface to show and manage toast notifications.
 * It exposes methods to create toasts with different types: success, warning, info, and error,
 * as well as a method to remove a toast manually by its ID.
 *
 * @example
 * const toast = useToast();
 *
 * // Show a success toast
 * toast.success({ title: 'Operation successful', content: 'The data has been saved successfully.' });
 *
 * // Show an error toast with a custom duration
 * toast.error({ title: 'Operation failed', content: 'An error occurred while saving the data.', duration: 5000 });
 *
 * // Remove a toast manually by its ID
 * toast.remove(toastId);
 */
export const useToast = () => useContext(ToastContext);
