export default function Return({ className }) {
	return (
		<svg
			viewBox='0 0 24 24'
			role='img'
			xmlns='http://www.w3.org/2000/svg'
			aria-labelledby='returnIconTitle'
			stroke='#000000'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			fill='none'
			color='#000000'
			className={className}
		>
			{' '}
			<title id='returnIconTitle'>Return</title>{' '}
			<path d='M19,8 L19,11 C19,12.1045695 18.1045695,13 17,13 L6,13' />{' '}
			<polyline points='8 16 5 13 8 10' />{' '}
		</svg>
	);
}
