export const collapseToast = (node, onCollapse, duration) => {
  const { scrollHeight, style } = node;

  requestAnimationFrame(() => {
    style.height = `${scrollHeight}px`;
    style.transition = `height ${duration}ms, opacity ${duration}ms`;

    requestAnimationFrame(() => {
      style.height = '0';
      style.opacity = '0';
      style.padding = '0';
      style.margin = '0';
      setTimeout(() => onCollapse(), duration);
    });
  });
};
