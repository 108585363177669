export function fractionate(val, minVal, maxVal) {
	return (val - minVal) / (maxVal - minVal);
}

export function modulate(val, minVal, maxVal, outMin, outMax) {
	var fr = fractionate(val, minVal, maxVal);
	var delta = outMax - outMin;
	return outMin + fr * delta;
}

export function avg(arr) {
	var total = arr.reduce(function (sum, b) {
		return sum + b;
	});
	return total / arr.length;
}

export function max(arr) {
	return arr.reduce(function (a, b) {
		return Math.max(a, b);
	});
}

export function makeRoughBall(sphere, bassFr, isLoading) {
	if (!sphere) return;

	const offset = sphere.geometry.parameters.radius;
	const time = performance.now() * 0.003;
	const spikes = 5;

	sphere.geometry.vertices.forEach((vertex) => {
		vertex.normalize();

		let deformation = 0;
		if (isLoading) {
			deformation = window.noise.perlin3(
				vertex.x * spikes,
				vertex.y * spikes,
				vertex.z * spikes + time
			);
		} else {
			const noiseValue = window.noise.perlin3(
				vertex.x * spikes,
				vertex.y * spikes,
				vertex.z * spikes + (bassFr > 0 ? time : 0)
			);
			deformation =
				bassFr > 0 ? bassFr * 0.3 + noiseValue : bassFr * 0.3 * noiseValue;
		}

		vertex.multiplyScalar(offset + deformation);
	});

	sphere.geometry.verticesNeedUpdate = true;
	sphere.geometry.normalsNeedUpdate = true;
	sphere.geometry.computeVertexNormals();
	sphere.geometry.computeFaceNormals();
	sphere.rotation.x += bassFr * 0.05;
}

export async function measurePerformance(
	asyncFunction,
	{ name = asyncFunction.name, enableLogging = true } = {}
) {
	let startTime, endTime;

	if (enableLogging) {
		startTime = performance.now();
	}

	const result = await asyncFunction();

	if (enableLogging) {
		endTime = performance.now();
		const duration = (endTime - startTime) / 1000;
		console.log(`${name} time elapsed: ${duration}s.`);
	}

	return result;
}

export function generateTimestamp() {
	const now = new Date();
	const hours = String(now.getHours()).padStart(2, '0');
	const minutes = String(now.getMinutes()).padStart(2, '0');
	const seconds = String(now.getSeconds()).padStart(2, '0');
	return `${hours}:${minutes}:${seconds}`;
}

export function getInitials(firstName, lastName) {
	const firstInitial = firstName ? firstName[0] : '';
	const lastInitial = lastName ? lastName[0] : '';
	return firstInitial + lastInitial;
}

// export function concatArrayBuffers(...bufs) {
//   const result = new Uint8Array(
//     bufs.reduce((totalSize, buf) => totalSize + buf.byteLength, 0)
//   );
//   bufs.reduce((offset, buf) => {
//     result.set(buf, offset);
//     return offset + buf.byteLength;
//   }, 0);
//   return result.buffer;
// }
