import React, { useCallback, useState, createContext } from 'react';

import { Toast } from '../ui';

const styles = {
  toastContainer: `z-50 fixed inset-x-4 sm:inset-x-auto bottom-8 sm:bottom-4 sm:right-4 flex flex-col-reverse items-center pointer-events-none`,
};

export const ToastContext = createContext({
  success: () => {},
  warning: () => {},
  info: () => {},
  error: () => {},
  remove: () => {},
});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const addToast = useCallback((toast) => {
    const id = new Date().getTime();
    setToasts((currentToasts) => [...currentToasts, { ...toast, id }]);
  }, []);

  const createToast = (type) => (toast) => {
    addToast({ ...toast, type });
  };

  const success = createToast('success');
  const warning = createToast('warning');
  const info = createToast('info');
  const error = createToast('error');

  const remove = (id) => {
    setToasts((currentToasts) =>
      currentToasts.filter((toast) => toast.id !== id)
    );
  };

  const value = React.useMemo(
    () => ({ success, warning, info, error, remove }),
    []
  );

  return (
    <ToastContext.Provider value={value}>
      {children}

      <div className={styles.toastContainer}>
        {toasts.map((toast) => (
          <Toast {...toast} key={toast.id} onDismiss={remove} />
        ))}
      </div>
    </ToastContext.Provider>
  );
};
