import { LogoType } from './icons';

const styles = {
	header: `z-40 flex justify-between items-center px-4 sm:px-10 pt-8 pb-5`,
	logo: `shrink-0 w-40 text-white`,
	itemList: `flex gap-2`,
	itemButton: `h-7 w-7 rounded-full`,
	itemIcon: `h-full w-full text-white/50`,
};

export default function Header() {
	return (
		<div className={styles.header}>
			<LogoType className={styles.logo} />
		</div>
	);
}
