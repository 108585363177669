import React from 'react';
import { cn } from '../../utils';

const styles = {
  container: `relative inline-flex items-center py-1 cursor-pointer`,
  radioUnchecked: `hover:opacity-60 opacity-transition`,
  radioContainer: `relative flex items-center rounded-full cursor-pointer`,
  radioInput: `peer relative h-5 w-5 rounded-full border appearance-none border-white checked:border-none checked:bg-[#ee4599]`,
  radioBlur: `absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 h-4 w-4 blur-md bg-[#ee4599] opacity-0 peer-checked:opacity-100 transition-opacity duration-300 ease-out`,
  radioLabel: `ms-2 text-white cursor-pointer`,
  radioChecked: `absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 h-2.5 w-2.5 bg-white rounded-full opacity-0 peer-checked:opacity-100 text-white transition-opacity duration-300 ease-out`,
};

const RadioOption = React.forwardRef(
  ({ id, label, type, isChecked, ...props }, ref) => {
    return (
      <div
        className={cn(styles.container, {
          [styles.radioUnchecked]: !isChecked,
        })}
      >
        <label className={styles.radioContainer}>
          <input
            name={id}
            type={type}
            value={id}
            id={id}
            checked={isChecked}
            ref={ref}
            {...props}
            className={styles.radioInput}
          />

          <div className={styles.radioBlur}></div>
          <div className={styles.radioChecked}></div>
        </label>

        <label htmlFor={id} className={styles.radioLabel}>
          {label}
        </label>
      </div>
    );
  }
);

export default RadioOption;
