export default function Pause({ className }) {
	return (
		<svg
			fill='currentColor'
			stroke='currentColor'
			viewBox='0 0 32 32'
			xmlns='http://www.w3.org/2000/svg'
			className={className}
		>
			<path d='M5.92 24.096q0 0.832 0.576 1.408t1.44 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.44 0.576t-0.576 1.44v16.16zM18.016 24.096q0 0.832 0.608 1.408t1.408 0.608h4.032q0.832 0 1.44-0.608t0.576-1.408v-16.16q0-0.832-0.576-1.44t-1.44-0.576h-4.032q-0.832 0-1.408 0.576t-0.608 1.44v16.16z'></path>
		</svg>
	);
}
