import { cn } from '../../utils';

const styles = {
  ringContainer: `inline-block relative`,
  ringChild: `box-border block absolute w-[80%] h-[80%] m-2 border-t-inherit rounded-full border-transparent animate-lds-ring`,
};

const animationDelays = ['0.45s', '0.3s', '0.15s', '0s'];

export default function Loader({ className, borderWidth }) {
  return (
    <div className={cn(styles.ringContainer, className)}>
      {animationDelays.map((delay, index) => (
        <div
          key={index}
          className={styles.ringChild}
          style={{ animationDelay: delay, borderWidth: borderWidth }}
        ></div>
      ))}
    </div>
  );
}
