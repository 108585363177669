import { useEffect } from 'react';

import { useInterviewer, ActionTypes } from '../context/InterviewerContext';
import { useToast } from './useToast';

import { measurePerformance } from '../utils/helpers';
import { createConversation, textToSpeech } from '../utils/api';

export function useFetchAudio(type) {
  const { dispatch } = useInterviewer();

  const toast = useToast();

  useEffect(() => {
    async function fetchAudio() {
      try {
        const { data } = await measurePerformance(
          () => createConversation(type),
          { name: 'Create Conversation', enableLogging: true }
        );
        const { assistant_message, conversation_id } = data;

        const voiceData = await textToSpeech(assistant_message, type);

        dispatch({
          type: ActionTypes.SET_AUDIO_DATA,
          payload: {
            voiceData: voiceData.data,
            conversationId: conversation_id,
          },
        });
      } catch (error) {
        dispatch({ type: 'SET_ERROR', payload: error.message });
        console.log('err', error);
        toast.error({
          title: 'Error',
          content:
            'An error occurred while fetching audio data. Please try again later.',
        });
      }
    }

    fetchAudio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, type, toast]);
}